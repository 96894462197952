/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "catergram-dev-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://catergram-dev-dev.s3-website-us-east-1.amazonaws.com",
    "aws_appsync_graphqlEndpoint": "https://bpt4aajfhrcblpleu5rc2dtcgy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:d830870e-3570-44f9-99d6-59c3ec08352f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7FLX3sEz8",
    "aws_user_pools_web_client_id": "2jt499h83jjj9dp3g8cu8dau9i",
    "oauth": {},
    "aws_user_files_s3_bucket": "shadid-dev212011-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "cloud_front_url": "https://d4u67kjr44gk7.cloudfront.net/public",
    "googel_geocode_api": "AIzaSyBieR1y1HOrDWOCtrmhnn49kczxQtMN5uQ"
};


export default awsmobile;
