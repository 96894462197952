import React from 'react';
import logo from './logo.svg';
import './App.css';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import {API, graphqlOperation } from 'aws-amplify';
import { createUserInvite } from './graphql/mutations';

function App() {

  const createAInvite =  async () => {
    const fp = await FingerprintJS.load();

    // The FingerprintJS agent is ready.
    // Get a visitor identifier when you'd like to.
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    console.log(JSON.stringify(result));

    // let input = {
    //   uuid: 'some-uuid',
    //   os: 'some-os'
    // };
    // try {
    //   const result =  await API.graphql(graphqlOperation(createUserInvite,  { input }));
    //   console.log('show all result ---->>>>', result);
    // } catch (error) {
    //     console.log(error)
    // }
  }

  return (
    <div className="App">
      <div>You Don't Have The App Installed</div>
      <button onClick={() => {createAInvite()}}>Go To App Store</button>
    </div>
  );
}

export default App;
